$.fn.clickToggle = function(t, a) {
  return this.each(function() {
    var e = !1;
    $(this).bind("click", function() {
      return e ? (e = !1, a.apply(this, arguments)) : (e = !0, t.apply(this, arguments))
    })
  })
}, $(".select-all").on("click", function() {
  this.checked ? $(this).parents("table").find(".checkbox-tick").each(function() {
    this.checked = !0
  }) : $(this).parents("table").find(".checkbox-tick").each(function() {
    this.checked = !1
  })
});
